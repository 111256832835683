<template>
    <div class="upload-page">
        <div class="header">
            <h1>扫码上传 更快捷</h1>
            <p>快去上传你的视频，试一试吧~</p>
        </div>
        <div class="title">视频上传</div>
        <div class="outer-container">
            <div class="inner-container" v-for="(item, index) in videos" :key="index">
                <div class="inner-box">
                    <template v-if="index === 0">
                        <el-upload
                            class="upload-box"
                            action=""
                            list-type="picture-card"
                            :multiple="false"
                            :http-request="uploadvideo"
                            accept="video/*"
                            :show-file-list="false">
                            <div class="upload-content">
                                <i class="el-icon-plus upload-icon"></i>
                                <p>点击上传</p>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <!-- 其他内容可以放在这里 -->
                        <div class="img-container">
                            <el-image style="width: 100%;height: 100%" :src="item.img_url" :preview-src-list="[item['img_url']]"></el-image>
                            <i class="el-icon-close delete-icon" @click="handleDelete(index)"></i>
                        </div>

                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'uploadVideo',
    components: {},
    data() {
        return {
            uuid: this.$route.query.uuid,
            type: this.$route.query.type,
            sign: this.$route.query.sign,
            videos: [{url:""}], // 已上传图片列表
        };
    },
    mounted() {
        this.getMUserInfo();
    },
    methods: {
        getMUserInfo() {
            this.newApi.getMUserInfo({ uuid: this.uuid }).then((res) => {
                if (res.isSuccess === 1) {
                    localStorage.token = res.data.token;
                    localStorage.uuid = res.data.uuid;
                }
            });
        },
        uploadvideo(params) {
            var that = this;
            var file = params.file;
            if (file.type.indexOf('video') > -1) {
                if(that.videos.length >= 2){
                    that.utils.err("只能传一个视频");
                    return false;
                }
                that.utils.upload(file,function(url) {
                    if (!url){
                        return false;
                    }
                    that.newApi.addCodeFile({ url: url.url, type: that.type, sign: that.sign }).then((res) => {
                        if (res.isSuccess == 1) {
                            for (let i = 0; i < that.videos.length; i++) {
                                res.data.img_url = res.data.url + "?x-oss-process=video/snapshot,t_0,f_jpg";
                                console.log( that.videos[i].url)
                                if(that.videos[i].url == res.data.url){
                                    that.utils.err("请勿重复上传");
                                    return false;
                                }
                            }
                            that.videos.push(res.data);
                            that.utils.sus("上传成功");
                        }
                    });
                })
            }else{
                this.$message.error('只能上传视频！')
                return false
            }
        },
        handleDelete(index){
            var that= this;
            that.utils.confirm("会与PC端同步删除，确认删除吗？", () => {
                that.newApi.delCodeFiles({type: this.type,url: that.videos[index].url}).then((res) => {
                    if (res.isSuccess === 1) {
                        that.utils.sus("删除成功");
                        that.videos.splice(index, 1);
                    }
                });

            });
        }
    }
};
</script>

<style>
.el-upload--picture-card{
    width: 100%;height: 100%;
}
.upload-page {
    padding: 0px;
}

.header {
    text-align: center;
}

.title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.outer-container {
    display: flex;
    flex-wrap: wrap;
}

.inner-container {
    width: 50%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px; /* 控制缩小的距离 */
}

.inner-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #409eff;
    border-radius: 8px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
}

.upload-box:hover {
    border-color: #66b1ff;
    background-color: #e6f7ff;
}

.upload-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.upload-icon {
    font-size: 32px;
    color: #409eff;
}

.img-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.delete-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    color: white;
    background-color: red;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
}
</style>
